$background-color: 		#fcfcfc;

$black:					#000;
$white:					#fff;

$grey-color-darker: 	#111;
$grey-color-dark: 		#333;
$grey-color-normal: 	#666;
$grey-color-light: 		#aaa;
$grey-color-lighter: 	#ddd;
$grey-color-lightest: 	#eee;

// $primary-color:			#1abc9c;
$primary-color:			#673ab7;

$facebook-brand-color:	#3b5998;
$twitter-brand-color:	#55acee;

$default-width:			1100px;
$on-tablet:				768px;
$on-smaller-tablets:    640px;
$on-phone:				480px;

$burger-cell-height:    2px;

// stuff for highlighting

$code-inline-bg: $grey-color-lightest;
$gray-800: $grey-color-lightest;
$gray-200: $grey-color-lightest;
$gray-500: $grey-color-lightest;
$code-bg: #333333;
