figure.highlight,
pre.highlight {
    padding: 1rem 1.5rem;
    margin: 1.5rem 0;
    overflow: auto;
    line-height: 1.4rem;
    position: relative;
}

.highlighter-rouge {
    font-weight: 300;
    position: relative;
}

p code.highlighter-rouge,
li code.highlighter-rouge,
p kbd {
    color: #cc6666;
    font-size: 1rem;
    background-color: $code-inline-bg;
    padding: 2px 4px;
    border-radius: 2px;
    word-break: break-word;
}

kbd {
    border: 1px solid $gray-800;
    border-radius: 4px;
}

/* "data-lang" should be injected by JavaScript */
/*
figure.highlight::before,
div.highlighter-rouge::before {
    font-family: $font-family-monospace;
    content: attr(data-lang);
    font-size: .9rem;
    color: $gray-200;
    position: absolute;
    display: block;
    height: 1rem;
    right: .5rem;
    z-index: 5;
}

figure.highlight::before {
    margin-top: -1rem;
}
*/

.rouge-table .code pre {
    overflow: hidden;
}

.rouge-table .code pre {
    overflow: hidden;
}

table.rouge-table,
table.rouge-table tr,
table.rouge-table td,
table.rouge-table pre {
    border: 0;
    padding: 0;
    margin: 0;
}

table.rouge-table td.gutter {
    padding-right: .5rem;
    border-right: 1px solid $gray-500;
}

table.rouge-table td.code {
    padding-left: .5rem;
}

figure pre {
    margin-bottom: 0;
}

.lineno {
    text-align: right;
}

/* Base16 Tomorrow Dark by Chris Kempson; https://github.com/idleberg */
.highlight,
.highlight pre,
.highlight table {
    background: $code-bg;
    color: $gray-200;
    border-radius: 4px;
    font-size: 1rem;
}

.highlight .hll { background-color: #373b41 }
.highlight .c { color: #969896 } /* Comment */
.highlight .err { color: #cc6666 } /* Error */
.highlight .k { color: #b294bb } /* Keyword */
.highlight .l { color: #de935f } /* Literal */
.highlight .n, .highlight .h { color: #ffffff } /* Name */
.highlight .o { color: #8abeb7 } /* Operator */
.highlight .p { color: #ffffff } /* Punctuation */
.highlight .cm { color: #969896 } /* Comment.Multiline */
.highlight .cp { color: #969896 } /* Comment.Preproc */
.highlight .c1 { color: #969896 } /* Comment.Single */
.highlight .cs { color: #969896 } /* Comment.Special */
.highlight .gd { color: #cc6666 } /* Generic.Deleted */
.highlight .ge { font-style: italic } /* Generic.Emph */
.highlight .gh { color: #ffffff; font-weight: bold } /* Generic.Heading */
.highlight .gi { color: #b5bd68 } /* Generic.Inserted */
.highlight .gp { color: #969896; font-weight: bold } /* Generic.Prompt */
.highlight .gs { font-weight: bold } /* Generic.Strong */
.highlight .gu { color: #8abeb7; font-weight: bold } /* Generic.Subheading */
.highlight .kc { color: #b294bb } /* Keyword.Constant */
.highlight .kd { color: #b294bb } /* Keyword.Declaration */
.highlight .kn { color: #8abeb7 } /* Keyword.Namespace */
.highlight .kp { color: #b294bb } /* Keyword.Pseudo */
.highlight .kr { color: #b294bb } /* Keyword.Reserved */
.highlight .kt { color: #f0c674 } /* Keyword.Type */
.highlight .ld { color: #b5bd68 } /* Literal.Date */
.highlight .m { color: #de935f } /* Literal.Number */
.highlight .s { color: #b5bd68 } /* Literal.String */
.highlight .na { color: #81a2be } /* Name.Attribute */
.highlight .nb { color: #ffffff } /* Name.Builtin */
.highlight .nc { color: #f0c674 } /* Name.Class */
.highlight .no { color: #cc6666 } /* Name.Constant */
.highlight .nd { color: #8abeb7 } /* Name.Decorator */
.highlight .ni { color: #ffffff } /* Name.Entity */
.highlight .ne { color: #cc6666 } /* Name.Exception */
.highlight .nf { color: #81a2be } /* Name.Function */
.highlight .nl { color: #ffffff } /* Name.Label */
.highlight .nn { color: #f0c674 } /* Name.Namespace */
.highlight .nx { color: #81a2be } /* Name.Other */
.highlight .py { color: #ffffff } /* Name.Property */
.highlight .nt { color: #8abeb7 } /* Name.Tag */
.highlight .nv { color: #cc6666 } /* Name.Variable */
.highlight .ow { color: #8abeb7 } /* Operator.Word */
.highlight .w { color: #ffffff } /* Text.Whitespace */
.highlight .mf { color: #de935f } /* Literal.Number.Float */
.highlight .mh { color: #de935f } /* Literal.Number.Hex */
.highlight .mi { color: #de935f } /* Literal.Number.Integer */
.highlight .mo { color: #de935f } /* Literal.Number.Oct */
.highlight .sb { color: #b5bd68 } /* Literal.String.Backtick */
.highlight .sc { color: #ffffff } /* Literal.String.Char */
.highlight .sd { color: #969896 } /* Literal.String.Doc */
.highlight .s2 { color: #b5bd68 } /* Literal.String.Double */
.highlight .se { color: #de935f } /* Literal.String.Escape */
.highlight .sh { color: #b5bd68 } /* Literal.String.Heredoc */
.highlight .si { color: #de935f } /* Literal.String.Interpol */
.highlight .sx { color: #b5bd68 } /* Literal.String.Other */
.highlight .sr { color: #b5bd68 } /* Literal.String.Regex */
.highlight .s1 { color: #b5bd68 } /* Literal.String.Single */
.highlight .ss { color: #b5bd68 } /* Literal.String.Symbol */
.highlight .bp { color: #ffffff } /* Name.Builtin.Pseudo */
.highlight .vc { color: #cc6666 } /* Name.Variable.Class */
.highlight .vg { color: #cc6666 } /* Name.Variable.Global */
.highlight .vi { color: #cc6666 } /* Name.Variable.Instance */
.highlight .il { color: #de935f } /* Literal.Number.Integer.Long */
// .highlight { background: #fff; border: 1px solid $grey-color-lightest; padding: 20px 10px;
// 	.lineno { padding: 0 15px 0 0; }
// 	.highlighter-rouge & { background: #eef; }
// 	.c     { color: #998; font-style: italic } // Comment
// 	.err   { color: #a61717; background-color: #e3d2d2 } // Error
// 	.k     { font-weight: bold } // Keyword
// 	.o     { font-weight: bold } // Operator
// 	.cm    { color: #998; font-style: italic } // Comment.Multiline
// 	.cp    { color: #999; font-weight: bold } // Comment.Preproc
// 	.c1    { color: #998; font-style: italic } // Comment.Single
// 	.cs    { color: #999; font-weight: bold; font-style: italic } // Comment.Special
// 	.gd    { color: #000; background-color: #fdd } // Generic.Deleted
// 	.gd .x { color: #000; background-color: #faa } // Generic.Deleted.Specific
// 	.ge    { font-style: italic } // Generic.Emph
// 	.gr    { color: #a00 } // Generic.Error
// 	.gh    { color: #999 } // Generic.Heading
// 	.gi    { color: #000; background-color: #dfd } // Generic.Inserted
// 	.gi .x { color: #000; background-color: #afa } // Generic.Inserted.Specific
// 	.go    { color: #888 } // Generic.Output
// 	.gp    { color: #555 } // Generic.Prompt
// 	.gs    { font-weight: bold } // Generic.Strong
// 	.gu    { color: #aaa } // Generic.Subheading
// 	.gt    { color: #a00 } // Generic.Traceback
// 	.kc    { font-weight: bold } // Keyword.Constant
// 	.kd    { font-weight: bold } // Keyword.Declaration
// 	.kp    { font-weight: bold } // Keyword.Pseudo
// 	.kr    { font-weight: bold } // Keyword.Reserved
// 	.kt    { color: #458; font-weight: bold } // Keyword.Type
// 	.m     { color: #099 } // Literal.Number
// 	.s     { color: #d14 } // Literal.String
// 	.na    { color: #008080 } // Name.Attribute
// 	.nb    { color: #0086B3 } // Name.Builtin
// 	.nc    { color: #458; font-weight: bold } // Name.Class
// 	.no    { color: #008080 } // Name.Constant
// 	.ni    { color: #800080 } // Name.Entity
// 	.ne    { color: #900; font-weight: bold } // Name.Exception
// 	.nf    { color: #900; font-weight: bold } // Name.Function
// 	.nn    { color: #555 } // Name.Namespace
// 	.nt    { color: #000080 } // Name.Tag
// 	.nv    { color: #008080 } // Name.Variable
// 	.ow    { font-weight: bold } // Operator.Word
// 	.w     { color: #bbb } // Text.Whitespace
// 	.mf    { color: #099 } // Literal.Number.Float
// 	.mh    { color: #099 } // Literal.Number.Hex
// 	.mi    { color: #099 } // Literal.Number.Integer
// 	.mo    { color: #099 } // Literal.Number.Oct
// 	.sb    { color: #d14 } // Literal.String.Backtick
// 	.sc    { color: #d14 } // Literal.String.Char
// 	.sd    { color: #d14 } // Literal.String.Doc
// 	.s2    { color: #d14 } // Literal.String.Double
// 	.se    { color: #d14 } // Literal.String.Escape
// 	.sh    { color: #d14 } // Literal.String.Heredoc
// 	.si    { color: #d14 } // Literal.String.Interpol
// 	.sx    { color: #d14 } // Literal.String.Other
// 	.sr    { color: #009926 } // Literal.String.Regex
// 	.s1    { color: #d14 } // Literal.String.Single
// 	.ss    { color: #990073 } // Literal.String.Symbol
// 	.bp    { color: #999 } // Name.Builtin.Pseudo
// 	.vc    { color: #008080 } // Name.Variable.Class
// 	.vg    { color: #008080 } // Name.Variable.Global
// 	.vi    { color: #008080 } // Name.Variable.Instance
// 	.il    { color: #099 } // Literal.Number.Integer.Long
// }
