@include media-query($on-tablet) {
	body { box-shadow: none; }
	.wrapper { padding: 120px 20px 50px; @include transition(padding .3s ease-in-out);
		&.active { padding: 30px 20px; }
	}
	.user-profile { position: fixed; top: 0; right: -100%; margin: 0; width: 300px; height: 100%; padding: 40px 30px 30px; z-index: 9999; background: $white; box-shadow: 0 0 30px rgba($black, .15);
		&.active { right: 0; overflow-y: auto;
			.compact-header { display: block; position: static; top: -100px; width: 100%; padding: 0; box-shadow: none;
				.avatar { margin: 0 auto 5px; }
				.my-info { text-align: center; }
			}
		}
		.compact-header { @include flexbox(); @include align-items(); position: fixed; top: 5px; left: 0; width: 100%; padding: 20px; background: $white; box-shadow: 0 2px 10px rgba($black, .1), 0 -5px 0 $primary-color; z-index: 9998;
			.avatar { margin: 0 20px 0 0; display: inline-block;
				img { width: 50px; height: 50px; }
			}
			.my-info { text-align: left;
				.my-name { font-size: 18px; line-height: 26px; }
				.my-job-title { font-size: 13px; }
			}
		}
		.mainmenu, .socials { margin: 20px 0; }
		.about-me { font-size: 15px; line-height: 24px; margin: 20px 0 0; }
		.burger { display: block !important; position: fixed; top: 40px; right: 20px; z-index: 9999; }
	}
	.the-content { width: 100%; margin: 0; }
	.backtotop { width: 30px !important; height: 30px !important; bottom: 10px !important; right: 10px !important; background-size: 50% !important; }
}

@include media-query($on-smaller-tablets) {
	.the-content .post { padding: 20px 20px 120px;
		.post-content {
			.heading-marker { position: static; left: auto; top: auto; font-size: inherit; margin: 0 0 0 10px; @include transform-none(); }
			.alignleft, .alignright { float: none; margin: 20px 0;
				img { width: 100%; }
			}
		}
	}
}

@include media-query($on-phone) {
	.the-content .search > div .search-holder { width: 45%; }
	.the-content .post .post-footer div.tags { position: static; text-align: left; border-top: 1px solid $grey-color-lightest; }
}